import { FC } from 'react';
import { ServiceCategory } from '../dtos/services.types';
import ServiceIcon from './service-icon';
import Image from 'next/image';
const ServicesCarouselCard: FC<{
  data: ServiceCategory;
  selectedCategory: number | null;
  clickEvent: any;
}> = ({
  data,
  selectedCategory,
  clickEvent
}) => {
  const isActive = selectedCategory === data.category_id;
  return <div className="p-1 mb-8 md:mb-0" onClick={clickEvent} data-sentry-component="ServicesCarouselCard" data-sentry-source-file="service-carousel-card.tsx">
            <div className={`h-[196px] border-b-2 border-transparent transition-all hover:border-amber-400 flex justify-start items-center flex-col cursor-pointer ${isActive ? 'border-amber-400' : ''}`}>
                <div className={`h-[100px] w-[100px] p-[1.5rem] flex items-center justify-around rounded-full  transition-all ${isActive ? 'shadow-none border-amber-400 border-4' : 'border-text border-[1px] shadow-md'}`}>
                    <Image src={`/images/categories/icons/${data.category_id}.svg`} alt={`${data.category_name} icon`} width={50} height={150} onError={e => e.currentTarget.src = '/images/categories/icons/other.svg'} data-sentry-element="Image" data-sentry-source-file="service-carousel-card.tsx" />
                </div>
                <h3 className="text-lg text-text text-center mt-4">
                    {data.category_name.toUpperCase()}
                </h3>
            </div>
        </div>;
};
export default ServicesCarouselCard;