import { useGetServices } from '@/features/services/api/use-get-services';
import { SupplierAssignedServices } from '@/features/supplier/dtos/supplier.type';

/**
 * @note
 * When it comes to returning data for supplier (or anything else other than services). The API returns it
 * in English language. This is a problem since the website is mainly for BG audiance. It was discussed to handle it on our side.
 * This hook pretty much deals with data and returns the appropriate language.
 */

const useTranslatedService = (data: SupplierAssignedServices) => {
    const servicesResponse = useGetServices();
    const allServices = servicesResponse?.data?.data?.categories;

    const serviceCategory = allServices?.find(
        (service) => service.category_id === data.category.id
    );

    return serviceCategory?.services.find((service) => service.id === data.id) // no need to check for language because useGetServices returns the translated versions only.
        ?.name;
};

export default useTranslatedService;
