import { useQuery } from '@tanstack/react-query';
import { ServicesQueryKeys } from './query-keys';
import api from '@/utils/fetch';
import { GetAllServicesResponse } from '../dtos/services.types';
import { useRouter } from 'next/router';
export function useGetServices() {
  const {
    locale
  } = useRouter();
  return useQuery<{
    data: GetAllServicesResponse;
  }, Error>({
    queryKey: [ServicesQueryKeys.All, locale],
    queryFn: () => api.get('/services/all', {
      headers: {
        'Accept-Language': locale
      }
    })
  });
}