import { FC } from 'react';
import { StarsByCategory, SupplierAssignedServices } from '../dtos/supplier.type';
import { Star } from 'lucide-react';
import useTranslatedService from '@/hooks/use-translated-service';

/**
 * @note :
 * There are cases where there are no stars for a certain categories. I discussed it with Ihor
 * and we decided to handle that by not displaying a star on the right side
 */
const ProfileCardServiceTag: FC<{
  data: SupplierAssignedServices;
  starsByCategory: StarsByCategory[];
}> = ({
  data,
  starsByCategory
}) => {
  const categoryStar = starsByCategory?.length ? starsByCategory?.find(star => star.category_id === data.category.id)?.stars : '';
  const translatedServiceName = useTranslatedService(data);
  return <div className="flex justify-between items-center mb-2" data-sentry-component="ProfileCardServiceTag" data-sentry-source-file="profile-card-service-tag.tsx">
            <div className="text-[#2E7D32]">{translatedServiceName}</div>
            {starsByCategory?.length && categoryStar ? <div className="bg-[#545756] rounded-[20px] py-[4px] pl-[7px] pr-[8px] flex items-center gap-[5px]">
                    <div>
                        <Star fill="#F9C339" stroke="#F9C339" size="16" />
                    </div>
                    <div className="text-white font-light text-[.75rem]">
                        {categoryStar}
                    </div>
                </div> : <></>}
        </div>;
};
export default ProfileCardServiceTag;