import { useQuery } from '@tanstack/react-query';
import { SupplierQueryKeys } from './query-keys';
import { useRouter } from 'next/router';
import api from '@/utils/fetch';
import { PublicSupplierProfileResponse } from '../dtos/supplier.type';
export function useGetPublicProfile(from: number = 1, to: number = 10, serviceIds?: string, searchTitle?: string, cityIds?: string) {
  const {
    locale
  } = useRouter();
  return useQuery<{
    data: PublicSupplierProfileResponse;
  }>({
    queryKey: [locale, SupplierQueryKeys.AllPublicProfiles, {
      from,
      to,
      serviceIds,
      searchTitle,
      cityIds
    }],
    queryFn: () => api.post(`/search/profile`, {
      service_ids: serviceIds ? [Number(serviceIds)] : [],
      text: searchTitle || '',
      city_ids: cityIds ? [Number(cityIds)] : [],
      order_by: 'created',
      order: 1,
      from,
      to
    }, {
      headers: {
        'Accept-Language': locale
      }
    })
  });
}