import { FC } from 'react';
import { PublicSupplierProfile } from '../dtos/supplier.type';
import { prepareRichTextHtml, truncateText } from '@/utils/helpers';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import Image from 'next/image';
import { supplierProfileCardData } from '../data/supplier-profile-card';
import ProfileCardServiceTag from './profile-card-service-tag';
import logoSrc from '@public/images/logo.png';
const ProfileCard: FC<{
  supplier: PublicSupplierProfile;
}> = ({
  supplier
}) => {
  const router = useRouter();
  const {
    i18n
  } = useTranslation();
  const language = i18n.language as 'bg' | 'en';
  const navigateToSupplierProfile = () => {
    router.push('/categories/portfolio/' + supplier?.uuid);
  };
  const avatar = supplier?.avatar?.length ? supplier?.avatar?.find(avatar => avatar.size === 'medium')?.url : '';
  return <div className="px-[40px] transition-all bg-white py-[24px] flex flex-col lg:flex-row gap-[20px] lg:gap-[70px] border-border justify-between border-[1px] rounded-md mb-4" data-sentry-component="ProfileCard" data-sentry-source-file="profile-card.tsx">
            <div className="w-[165px] flex h-[165px] border-[#DEE2E6] mx-auto lg: ml-auto border-[1px] rounded-md cursor-pointer" onClick={navigateToSupplierProfile}>
                <Image src={avatar || logoSrc} width="130" height="130" className="w-[130px] h-[130px] m-auto rounded-md object-cover" alt={`${supplier?.name} | Profile card`} data-sentry-element="Image" data-sentry-source-file="profile-card.tsx" />
            </div>
            <div className="flex-1">
                <h3 className="text-text text-center lg:text-left font-size text-2xl font-light">
                    {supplier.name}
                </h3>
                <div className="mt-4 text-center lg:text-left">
                    {supplier.supplier_is_online ? <span className="pb-[7px] pt-[8px] px-4 bg-[#E4E7E9] text-green-500 text-[.813rem] font-light rounded-[16px]">
                            {supplierProfileCardData[language].status.active}
                        </span> : <span className="pb-[7px] pt-[8px] px-4 bg-[#E4E7E9] text-red-500 text-[.813rem] font-light rounded-[16px]">
                            {supplierProfileCardData[language].status.inactive}
                        </span>}
                </div>
                <div className="mt-4 ">
                    {supplier.description ? <p className="text-text" dangerouslySetInnerHTML={{
          __html: prepareRichTextHtml(truncateText(supplier.description))
        }} /> : ''}

                    {supplier?.description && supplier?.description.length > 100 ? <p className="text-right mt-2">
                            <a href={'/categories/portfolio/' + supplier?.uuid} className="text-[0.831rem] text-[#1E905D]">
                                {supplierProfileCardData[language].readMore}
                            </a>
                        </p> : ''}
                </div>
            </div>
            <div className="lg:max-w-[239px] w-full">
                {supplier?.services?.map(service => <ProfileCardServiceTag key={service.id} data={service} starsByCategory={supplier?.stars_by_category} />)}
            </div>
            <div className="lg:max-w-[270px] w-full">
                <Button variant="secondary" size="full" onClick={navigateToSupplierProfile} data-sentry-element="Button" data-sentry-source-file="profile-card.tsx">
                    {supplierProfileCardData[language].button}
                </Button>
                <div className="mt-[24px] flex gap-[17px]">
                    <div className="rounded-md border-border border-[1px] py-[8px] px-[4px] w-fit">
                        <div className="text-center text-text text-2xl mb-[4px]">
                            {supplier.reviews_supplier.length}
                        </div>
                        <div className="text-center text-green-700">
                            {supplierProfileCardData[language].review}
                        </div>
                    </div>
                    <div className="rounded-md border-border border-[1px] py-[8px] px-[4px] w-full">
                        <div className="text-text text-2xl font-bold text-center mb-[4px]">
                            {supplier?.count_orders ?? 'XX'}
                        </div>
                        <div className="text-center text-green-700">
                            {supplierProfileCardData[language].orders}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
};
export default ProfileCard;