export const supplierProfileCardData = {
    en: {
        status: {
            active: 'Online',
            inactive: 'Offline',
        },
        readMore: 'Read more',
        button: 'Review the portfolio',
        review: 'Review',
        orders: 'Completed Orders',
    },
    bg: {
        status: {
            active: 'На линия',
            inactive: 'Офлайн',
        },
        readMore: 'Виж повече',
        button: 'Преглед на портфолиото',
        review: 'Отзиви',
        orders: 'Изпълнени поръчки',
    },
};
