import { FC, useEffect } from 'react';
import { useState } from 'react';
import { useGetServices } from '../api/use-get-services';
import { Loader } from '@/components/loader';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import useIsMobile from '@/hooks/use-is-mobile';
import ServicesCarouselCard from '../components/service-carousel-card';
import ServiceCarouselLink from '../components/service-carousel-link';
import { BREAKPOINTS } from '@/utils/constants/base';
const ServiceSupplierSearchCarousel: FC<{
  selectedService: number | null;
  handleServiceSelect: any;
}> = ({
  selectedService,
  handleServiceSelect
}) => {
  const {
    data,
    isLoading
  } = useGetServices();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const categories = data?.data?.categories.sort((first, second) => second.category_ordering - first.category_ordering);
  const isMobile = useIsMobile(BREAKPOINTS.mdLarge);
  const orderedCategories = categories?.sort((a, b) => a.category_ordering - b.category_ordering);
  useEffect(() => {
    if (selectedService && orderedCategories) {
      for (const category of orderedCategories) {
        if (category.services.some(service => service.id === selectedService)) {
          setSelectedCategory(category.category_id);
        }
      }
    } else {
      setSelectedCategory(orderedCategories ? orderedCategories[0].category_id : null);
    }
  }, [selectedService, orderedCategories]);
  if (!orderedCategories && isLoading) {
    return <div className="flex items-center justify-around">
                <Loader />
            </div>;
  }
  const selectedServices = orderedCategories?.find(category => category.category_id === selectedCategory)?.services;
  return <>
            <div className="w-full max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl ml-auto mr-auto">
                <div className="mb-8">
                    <Carousel className="w-full" opts={{
          align: 'start'
        }} data-sentry-element="Carousel" data-sentry-source-file="service-supplier-search-carousel.tsx">
                        <CarouselContent className="w-full" data-sentry-element="CarouselContent" data-sentry-source-file="service-supplier-search-carousel.tsx">
                            {orderedCategories?.map(category => <CarouselItem key={category.category_id} className="basis-1/2 md:basis-1/3 lg:basis-1/4">
                                    <ServicesCarouselCard key={category.category_id} data={category} selectedCategory={selectedCategory} clickEvent={() => {
                setSelectedCategory(category.category_id);
              }} />
                                </CarouselItem>)}
                        </CarouselContent>
                        {isMobile || categories && categories.length > 4 ? <>
                                <CarouselPrevious className="bg-white custom-left-arrow-position" />
                                <CarouselNext className="bg-white custom-right-arrow-position" />
                            </> : ''}
                    </Carousel>
                </div>
                <div className="flex gap-2 flex-wrap items-center justify-center">
                    {selectedServices?.map(service => <ServiceCarouselLink key={service.id} service={service} selectedService={selectedService} clickEvent={() => {
          handleServiceSelect(service.id);
        }} />)}
                </div>
            </div>
        </>;
};
export default ServiceSupplierSearchCarousel;