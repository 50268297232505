import { FC } from 'react';
import { Service } from '../dtos/services.types';
const ServiceCarouselLink: FC<{
  service: Service;
  selectedService: number | null;
  clickEvent: any;
}> = ({
  service,
  selectedService,
  clickEvent
}) => {
  return <div key={service.id} onClick={clickEvent} className={`border-text border-[1px] rounded-2xl px-4 py-[3px] transition-all cursor-pointer font-light ${selectedService === service.id ? 'text-white bg-text' : ''}`} data-sentry-component="ServiceCarouselLink" data-sentry-source-file="service-carousel-link.tsx">
            {service.name}
        </div>;
};
export default ServiceCarouselLink;